import React, { Component } from 'react';
import ReactDOM from 'react-dom';

export class Counter extends Component {
  static displayName = Counter.name;

  constructor(props) {
    super(props);
    this.state = { currentCount: 0 };
      this.incrementCounter = this.incrementCounter.bind(this);
      //var Iframe = React.createClass({
      //    render: function () {
      //        return (
      //            <div>
      //                <iframe src={this.props.src} height={this.props.height} width={this.props.width} />
      //            </div>
      //        )
      //    }
      //});
      //ReactDOM.render(
      //    <iframe width="1140" height="541.25" src="https://app.powerbi.com/reportEmbed?reportId=9bb01f1d-fcb3-4263-878c-4acf0b34971f&autoAuth=true&ctid=77bc4974-415e-4eb0-8247-3cc4797c5545&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D"
      //        frameborder="0"
      //        allowFullScreen="true">
      //    </iframe>
      //)
  }

  incrementCounter() {
    this.setState({
      currentCount: this.state.currentCount + 1
    });
  }


  render() {
    return (
      <div>
        <h2>Power BI Display - Embedded</h2>

            <iframe width="1140" height="542"
                src="https://app.powerbi.com/reportEmbed?reportId=3ae9b4bd-9454-4101-a56c-7c8da2e59241&groupId=16a3833f-24ae-486e-bae4-1fb847a13525&autoAuth=true&ctid=77bc4974-415e-4eb0-8247-3cc4797c5545&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D"
                frameborder="0"
                allowFullScreen="true">
            </iframe>
      </div>
    );
  }
}
