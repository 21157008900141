import React from 'react';

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing,
    Pager,
    HeaderFilter,
    Grouping,
    GroupPanel,
    Export,
    SearchPanel,
    Selection,
    Format,
    FormItem,
    Editing,
    PatternRule,
    RequiredRule,
} from 'devextreme-react/data-grid';

import Box, {
    Item
} from 'devextreme-react/box';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CheckBox } from 'devextreme-react';
import CustomStore from "devextreme/data/custom_store";
import 'whatwg-fetch';


const allowedPageSizes = [10, 15, 20, 25, 30, 40, 50, 100];

class NetWorthReport extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            autoExpandAll: true
        };

        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        //this.onRowPrepared = this.onRowPrepared.bind(this);

        this.dataGrid = null;

        this.onAutoExpandAllChanged = this.onAutoExpandAllChanged.bind(this);
    }

    currencyFormat = { useGrouping: true };
    percentageFormat = { type: 'percent' };

    render() {
        return (
            <React.Fragment>
                <Box
                    direction="col"
                    width="100%"
                    height={250}>
                    <Item ratio={1}>
                        <div className="rect demo-dark header">
                            <FontAwesomeIcon icon="chart-line" /> Net Worth Report
                        </div>
                    </Item>
                    <Item
                        ratio={4}
                        baseSize={0}>
                        <Box
                            direction="row"
                            width="100%"
                            height={125}>
                            <Item ratio={14}>

                                <div>
                                    <DataGrid
                                        dataSource={gridDataSource}
                                        keyExpr="id"
                                        allowColumnReordering={true}
                                        showBorders={true}
                                        wordWrapEnabled={false}
                                        columnAutoWidth={true}
                                        showRowLines={true}
                                        showColumnHeaders={true}
                                    >
                                        <ColumnChooser enabled={true} />
                                        <ColumnFixing enabled={true} />
                                        <Pager
                                            showPageSizeSelector={true}
                                            allowedPageSizes={allowedPageSizes}
                                            showNavigationButtons={true}
                                            showInfo={true}
                                            infoText="Page {0} of {1} ({2} Net Worth Records)"
                                        />
                                        <Editing
                                            mode="form"
                                            allowUpdating={true}
                                            allowAdding={true}
                                            allowDeleting={true}
                                        />
                                        <Export enabled={true} fileName="NetWorthReport" allowExportSelectedData={true} />
                                        <Selection mode="multiple" />
                                        <GroupPanel visible={true} />
                                        <HeaderFilter
                                            visible={true}
                                            allowSearch={true}
                                        />
                                        <SearchPanel visible={true} searchVisibleColumnsOnly={true} />
                                        <Grouping autoExpandAll={this.state.autoExpandAll} />

                                        <Column dataField="ein" dataType="string" visible={true} fixed={false}>
                                            <PatternRule
                                                message={'EIN number should only contain digits'}
                                                pattern={/^[0-9]+$/}
                                            />
                                            <PatternRule
                                                message={'EIN number should be 9 digits'}
                                                pattern={/^\d{9}$/i}
                                            />
                                            <RequiredRule message="EIN is required" />
                                         </Column>
                                        <Column dataField="clubId" dataType="string" visible={true} fixed={false}>
                                            <FormItem visible={false} />
                                         </Column>
                                        <Column dataField="entityId" dataType="string" fixed={false} searchEnabled={true} allowHeaderFiltering={true} fixed={false} visible={false}>
                                            <FormItem visible={false} />
                                        </Column>
                                        <Column dataField="taxpayerName" dataType="string" fixed={false} searchEnabled={true} allowHeaderFiltering={true} fixed={false} visible={true}>
                                            <RequiredRule message="Taxpayer Name is required" />
                                        </Column>
                                        <Column dataField="addressLine1" dataType="string" fixed={false} searchEnabled={true} allowHeaderFiltering={true} fixed={false} visible={false} />
                                        <Column dataField="city" dataType="string" fixed={false} searchEnabled={true} allowHeaderFiltering={true} fixed={false} visible={false} />
                                        <Column dataField="state" dataType="string" fixed={false} searchEnabled={true} allowHeaderFiltering={true} fixed={false} visible={true} />
                                        <Column dataField="answerChoiceId" dataType="string" caption="Club Type(s)" fixed={false} searchEnabled={true} allowHeaderFiltering={true} fixed={false} visible={true} allowEditing={false} />
                                        <Column dataField="cagr" dataType="string" caption="CAGR" fixed={false} width={170}>
                                            <Format
                                                type="percent"
                                                precision={1}
                                            />
                                            <FormItem visible={false} />
                                        </Column>
                                        <Column dataField="zipCode" dataType="string" fixed={false} searchEnabled={true} allowHeaderFiltering={true} fixed={false} visible={false} />
                                        <Column dataField="taxYearFirst" dataType="string" fixed={false} searchEnabled={true} allowHeaderFiltering={true} fixed={false} visible={true}>
                                            <PatternRule
                                                message={'Tax Year should only contain digits'}
                                                pattern={/^[0-9]+$/}
                                            />
                                            <PatternRule
                                                message={'Tax Year should be 4 digits'}
                                                pattern={/^\d{4}$/i}
                                            />
                                            <RequiredRule message="First Tax Year is required" />
                                        </Column>
                                        <Column dataField="taxYearLast" dataType="string" fixed={false} searchEnabled={true} allowHeaderFiltering={true} fixed={false} visible={true}>
                                            <PatternRule
                                                message={'Tax Year should only contain digits'}
                                                pattern={/^[0-9]+$/}
                                            />
                                            <PatternRule
                                                message={'Tax Year should be 4 digits'}
                                                pattern={/^\d{4}$/i}
                                            />
                                            <RequiredRule message="Last Tax Year is required" />
                                        </Column>
                                        <Column dataField="netAssetsEoyFirstYear" number="number" format={this.currencyFormat}>
                                            <PatternRule
                                                message={'Net Assets should only contain digits'}
                                                pattern={/^[0-9]+$/}
                                            />
                                            <RequiredRule message="First Year Net Assets is required" />
                                        </Column>
                                        <Column dataField="netAssetsEoyLastYear" number="number" format={this.currencyFormat}>
                                            <PatternRule
                                                message={'Net Assets should only contain digits'}
                                                pattern={/^[0-9]+$/}
                                            />
                                            <RequiredRule message="Last Year Net Assets is required" />
                                        </Column>
                                    </DataGrid>
                                    <div className="options">
                                        <div className="caption">Options</div>
                                        <div className="option">
                                            <CheckBox text="Expand All Groups"
                                                value={this.state.autoExpandAll}
                                                onValueChanged={this.onAutoExpandAllChanged} />
                                        </div>
                                    </div>
                                </div>
                            </Item>
                        </Box>
                    </Item>
                </Box>

            </React.Fragment>
        );
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
            location: 'before',
            template: 'totalGroupCount'
        }, {
            location: 'before',
            widget: 'dxSelectBox',
            options: {
                width: 200,
                items: [{
                    value: 'Id',
                    text: 'Grouping by Id'
                }, {
                    value: 'taxPayerName',
                    text: 'Grouping by Tax Payer Name'
                }],
                displayExpr: 'text',
                valueExpr: 'value',
                value: 'remoteDataSource'
            }
        },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'refresh',
                    onClick: this.refreshDataGrid.bind(this)
                }
            });
    }

    onRowPrepared(e) {
        if (e.rowType == 'data' && (e.data.clubId && e.data.entityId && e.data.ein && e.data.cagr)) {
            e.rowElement.style.backgroundColor = '#00A300';
        }
    }

    refreshDataGrid() {
        this.dataGrid.instance.refresh();
    }

    customizeExcelCell(options) {
        var e = options.gridCell;
        if (!e) {
            return;
        }
        if (e.rowType == 'data' && (e.data.clubId && e.data.entityId && e.data.ein && e.data.cagr)) {
            options.backgroundColor = '#00A300';
        }
    }

    onAutoExpandAllChanged() {
        this.setState({
            autoExpandAll: !this.state.autoExpandAll
        });
    }

}

function handleErrors(response) {
    if (!response.ok)
        throw Error(response.statusText);
    return response;
}
const gridDataSource = {
    store: new CustomStore({
        key: 'id',

        load: (values) => {
            return fetch("/api/NetWorths", {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(result => result.json())
                .catch(handleErrors);
        },
        insert: (values) => {
            console.log('values', values);
            delete values.id;

            var jsonString = JSON.stringify(values);
            return fetch('/api/NetWorths/', {
                method: "POST",
                body: jsonString,
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(handleErrors);
        },
        update: (key, values) => {
            console.log('values', values);
            var jsonString = JSON.stringify(values);
            return fetch('/api/NetWorths/' + key, {
                method: "PUT",
                body: jsonString,
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(handleErrors);
        },
        remove: (key) => {
            return fetch('/api/NetWorths/' + key, {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(handleErrors);
        }
    })
}

export default NetWorthReport;
