import React from 'react';

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

import { Template } from 'devextreme-react/core/template';

import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing,
    FormItem,
    Pager,
    HeaderFilter,
    Form,
    Lookup,
    Grouping,
    GroupPanel,
    Export,
    Scrolling,
    SearchPanel,
    Selection,
    StateStoring
} from 'devextreme-react/data-grid';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { CheckBox } from 'devextreme-react';
import CustomStore from "devextreme/data/custom_store";
import 'whatwg-fetch';



const allowedPageSizes = [10, 15, 20, 25, 30, 50, 100];

class TaxFormScheduleJ extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            autoExpandAll: true,
            showColumnLines: true,
            showRowLines: true,
            showBorders: true,
            rowAlternationEnabled: false,
            allowColumnReordering: true,
            wordWrapEnabled: true,
            columnAutoWidth: true,
            showColumnHeaders: true
        };

        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.onCellPrepared = this.onCellPrepared.bind(this);
        this.customizeExcelCell = this.customizeExcelCell.bind(this);

        this.dataGrid = null;

        this.onAutoExpandAllChanged = this.onAutoExpandAllChanged.bind(this);
    }

    currencyFormat = { style: 'decimal', useGrouping: true };

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
            location: 'before',
            template: 'totalGroupCount'
        }, {
            location: 'before',
            widget: 'dxSelectBox',
            options: {
                width: 200,
                items: [{
                    value: 'Id',
                    text: 'Grouping by Id'
                }, {
                    value: 'taxPayerName',
                    text: 'Grouping by Tax Payer Name'
                }],
                displayExpr: 'text',
                valueExpr: 'value',
                value: 'remoteDataSource'
            }
        }
            ,
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'refresh',
                    onClick: this.refreshDataGrid.bind(this)
                }
            });
    }

    refreshDataGrid() {
        this.dataGrid.instance.refresh();
    }

    render() {
        const { allowColumnReordering, wordWrapEnabled, columnAutoWidth, showColumnHeaders, showColumnLines, showRowLines, showBorders, rowAlternationEnabled } = this.state;
        return (
            <div>
                <DataGrid
                    //DataGrid id="gridContainer"
                    //ref={(ref) => this.dataGrid = ref}
                    dataSource={gridDataSource}
                    keyExpr="id"
                    allowColumnReordering={allowColumnReordering}
                    wordWrapEnabled={wordWrapEnabled}
                    columnAutoWidth={columnAutoWidth}
                    showColumnHeaders={showColumnHeaders}
                    showColumnLines={showColumnLines}
                    showRowLines={showRowLines}
                    showBorders={showBorders}
                    rowAlternationEnabled={rowAlternationEnabled}
                    onCellPrepared={this.onCellPrepared}
                >
                    <ColumnChooser enabled={true} />
                    <ColumnFixing enabled={true} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={allowedPageSizes}
                        showNavigationButtons={true}
                        showInfo={true}
                        infoText="Page {0} of {1} ({2} Schedule J Records)"
                    />
                    <Export enabled={true} fileName="TaxFormScheduleJReport" allowExportSelectedData={true} customizeExcelCell={this.customizeExcelCell} />
                    <Selection mode="multiple" />
                    <GroupPanel visible={true} />
                    <HeaderFilter visible={true} />
                    <SearchPanel visible={true} />
                    <Grouping autoExpandAll={this.state.autoExpandAll} />

                    <Column dataField="ein" dataType="string"  />
                    <Column dataField="taxpayerName" dataType="string" fixed={true} />
                    <Column dataField="taxYear" dataType="string" fixed={true} />
                    <Column dataField="addressLine1" dataType="string" visible={false} />
                    <Column dataField="city" dataType="string" format={this.currencyFormat} visible={false} />
                    <Column dataField="state" dataType="string" format={this.currencyFormat} />
                    <Column dataField="zipCode" dataType="string" format={this.currencyFormat} visible={false} />
                    <Column dataField="taxPeriod" dataType="string" />
                    <Column dataField="datetimecreated" dataType="string" visible={false} />
                    <Column dataField="returnVersion" dataType="string" visible={false} />
                    <Column dataField="taxPeriodEndDate" dataType="string" visible={false} />
                    <Column dataField="taxPeriodBeginDate" dataType="string" visible={false} />
                    <Column dataField="totalEmployeeCount" dataType="number" format={this.currencyFormat} />
                    <Column dataField="housingAllowanceOrResidenceInd" dataType="string" />
                    <Column dataField="compensationCommitteeInd" dataType="string" />
                    <Column dataField="writtenEmploymentContractInd" dataType="string" />
                    <Column dataField="compensationSurveyInd" dataType="string" />
                    <Column dataField="form990OfOtherOrganizationsInd" dataType="string" />
                    <Column dataField="boardOrCommitteeApprovalInd" dataType="string" />
                    <Column dataField="writtenPolicyRefTandEexpnssInd" dataType="string" />
                    <Column dataField="severancePaymentInd" dataType="string" />
                    <Column dataField="supplementalNonqualRtrPlanInd" dataType="string" />
                    <Column dataField="equityBasedCompArrngmInd" dataType="string" />
                    <Column dataField="substantiationRequiredInd" dataType="string" />
                </DataGrid>
                <div className="options">
                    <div className="caption">Options</div>
                    <div className="option">
                        <CheckBox text="Expand All Groups"
                            value={this.state.autoExpandAll}
                            onValueChanged={this.onAutoExpandAllChanged} />
                    </div>
                </div>
            </div>
        );
    }

    onAutoExpandAllChanged() {
        this.setState({
            autoExpandAll: !this.state.autoExpandAll
        });
    }

    onCellPrepared(e) {
        if (e.rowType == 'data') {
            if (e.column.dataField === "compensationCommitteeInd" && e.data.compensationCommitteeInd == "Yes") {
                e.cellElement.style.backgroundColor = '#00A300';
            }
            if (e.column.dataField === "housingAllowanceOrResidenceInd" && e.data.housingAllowanceOrResidenceInd == "Yes") {
                e.cellElement.style.backgroundColor = '#00A300';
            }
            if (e.column.dataField === "writtenPolicyRefTandEexpnssInd" && e.data.writtenPolicyRefTandEexpnssInd == "Yes") {
                e.cellElement.style.backgroundColor = '#00A300';
            }
            if (e.column.dataField === "substantiationRequiredInd" && e.data.substantiationRequiredInd == "Yes") {
                e.cellElement.style.backgroundColor = '#00A300';
            }
            if (e.column.dataField === "compensationSurveyInd" && e.data.compensationSurveyInd == "Yes") {
                e.cellElement.style.backgroundColor = '#00A300';
            }
            if (e.column.dataField === "form990OfOtherOrganizationsInd" && e.data.form990OfOtherOrganizationsInd == "Yes") {
                e.cellElement.style.backgroundColor = '#00A300';
            }
            if (e.column.dataField === "writtenEmploymentContractInd" && e.data.writtenEmploymentContractInd == "Yes") {
                e.cellElement.style.backgroundColor = '#00A300';
            }
            if (e.column.dataField === "boardOrCommitteeApprovalInd" && e.data.boardOrCommitteeApprovalInd == "Yes") {
                e.cellElement.style.backgroundColor = '#00A300';
            }
            if (e.column.dataField === "severancePaymentInd" && e.data.severancePaymentInd == "Yes") {
                e.cellElement.style.backgroundColor = '#00A300';
            }
            if (e.column.dataField === "supplementalNonqualRtrPlanInd" && e.data.supplementalNonqualRtrPlanInd == "Yes") {
                e.cellElement.style.backgroundColor = '#00A300';
            }
            if (e.column.dataField === "equityBasedCompArrngmInd" && e.data.equityBasedCompArrngmInd == "Yes") {
                e.cellElement.style.backgroundColor = '#00A300';
            }
        }
    }

    customizeExcelCell(options) {
        var e = options.gridCell;
        if (!e) {
            return;
        }
        if (e.rowType == 'data') {
            if (e.column.dataField === "compensationCommitteeInd" && e.data.compensationCommitteeInd == "Yes") {
                options.backgroundColor = '#00A300';
            }
            if (e.column.dataField === "housingAllowanceOrResidenceInd" && e.data.housingAllowanceOrResidenceInd == "Yes") {
                options.backgroundColor = '#00A300';
            }
            if (e.column.dataField === "writtenPolicyRefTandEexpnssInd" && e.data.writtenPolicyRefTandEexpnssInd == "Yes") {
                options.backgroundColor = '#00A300';
            }
            if (e.column.dataField === "substantiationRequiredInd" && e.data.substantiationRequiredInd == "Yes") {
                options.backgroundColor = '#00A300';
            }
            if (e.column.dataField === "compensationSurveyInd" && e.data.compensationSurveyInd == "Yes") {
                options.backgroundColor = '#00A300';
            }
            if (e.column.dataField === "form990OfOtherOrganizationsInd" && e.data.form990OfOtherOrganizationsInd == "Yes") {
                options.backgroundColorr = '#00A300';
            }
            if (e.column.dataField === "writtenEmploymentContractInd" && e.data.writtenEmploymentContractInd == "Yes") {
                options.backgroundColor = '#00A300';
            }
            if (e.column.dataField === "boardOrCommitteeApprovalInd" && e.data.boardOrCommitteeApprovalInd == "Yes") {
                options.backgroundColor = '#00A300';
            }
            if (e.column.dataField === "severancePaymentInd" && e.data.severancePaymentInd == "Yes") {
                options.backgroundColor = '#00A300';
            }
            if (e.column.dataField === "supplementalNonqualRtrPlanInd" && e.data.supplementalNonqualRtrPlanInd == "Yes") {
                options.backgroundColor = '#00A300';
            }
            if (e.column.dataField === "equityBasedCompArrngmInd" && e.data.equityBasedCompArrngmInd == "Yes") {
                options.backgroundColor = '#00A300';
            }
        }
    }

}

function handleErrors(response) {
    if (!response.ok)
        throw Error(response.statusText);
    return response;
}
const gridDataSource = {
    store: new CustomStore({
        //key: 'id',
        load: (values) => {
            return fetch("/api/taxformschedulejs", {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(result => result.json())
                .catch(handleErrors);
        },

        update: (key, values) => {
            console.log('values', values);
            var jsonString = JSON.stringify(values);
            return fetch('/api/ein/' + key, {
                method: "PUT",
                body: jsonString,
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(handleErrors);
        }
    })
}

export default TaxFormScheduleJ;
