import React from 'react';

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

import DataGrid, {
    Column,
    FormItem,
    Editing,
    Pager,
    HeaderFilter,
    SearchPanel,
    Form,
    PatternRule,
    RequiredRule,
    Validator,
    Lookup
} from 'devextreme-react/data-grid';
import {
    SimpleItem,
    GroupItem
} from 'devextreme-react/form';
import 'devextreme-react/text-area';
import CustomStore from "devextreme/data/custom_store";
import 'whatwg-fetch';


const allowedPageSizes = [10, 20, 50, 100];

class EINDataGrid extends React.Component {

    render() {
        return (
            <DataGrid
                dataSource={gridDataSource}
                keyExpr="id"
                showBorders={true}
            >
                <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={allowedPageSizes}
                    showNavigationButtons={true}
                    showInfo={true}
                    infoText="Page {0} of {1} ({2} Clubs)"
                />
                <Editing
                    mode="form"
                    allowUpdating={true}>
                    <Form>
                        <SimpleItem dataField="id" disabled={true}/>
                        <SimpleItem dataField="name" disabled={true}/>
                        <SimpleItem dataField="clubId" disabled={true}/>
                        <SimpleItem dataField="ein">
                                <PatternRule
                                    message={'EIN number should only contain digits'}
                                    pattern={/^[0-9]+$/}
                                />
                                <PatternRule
                                    message={'EIN number should be 9 digits'}
                                    pattern={/^\d{9}$/i}
                                />
                                <RequiredRule message="EIN is required" />
                        </SimpleItem>
                    </Form>
                </Editing>
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} />
                <Column dataField="id" caption="Id" width={100} />
                <Column dataField="name" />
                <Column dataField="clubId" width={100} />
                <Column dataField="ein" width={170} />
            </DataGrid>
        );
    }

}

function sendRequest(value) {
    const ein = value;
    // TO DO if needed
    return fetch("/api/<test>", {
        method: "GET",
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(result => result.json())
        .catch(handleErrors);
}

function asyncValidation(params) {
    return sendRequest(params.value);
}

function handleErrors(response) {
    if (!response.ok)
        throw Error(response.statusText);
    return response;
}

const gridDataSource = {
    store: new CustomStore({
        key: 'id',
        load: (values) => {
            return fetch("/api/cbeinids", {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(result => result.json())
            .catch(handleErrors);
        },

        update: (key, values) => {
            console.log('values', values);
            var jsonString = JSON.stringify(values);
            return fetch('/api/ein/' + key, {
                method: "PUT",
                body: jsonString,
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(handleErrors);
        }
    })
}

export default EINDataGrid;
