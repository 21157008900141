import React from 'react';

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

import DataGrid, {
    Column,
    FormItem,
    Editing,
    Pager,
    HeaderFilter,
    SearchPanel,
    Form,
    PatternRule,
    RequiredRule
} from 'devextreme-react/data-grid';
import {
    SimpleItem,
    GroupItem
} from 'devextreme-react/form';
import 'devextreme-react/text-area';
import CustomStore from "devextreme/data/custom_store";
import 'whatwg-fetch';


const allowedPageSizes = [10, 20, 50, 100];

class UtilityDupeNames extends React.Component {
    constructor(props) {
        super(props);
        this.onRowPrepared = this.onRowPrepared.bind(this);
    }
    render() {
        return (
            <DataGrid
                dataSource={gridDataSource}
                keyExpr="id"
                showBorders={true}
                onRowPrepared={this.onRowPrepared}
            >
                <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={allowedPageSizes}
                    showNavigationButtons={true}
                    showInfo={true}
                    infoText="Page {0} of {1} ({2} Clubs)"
                />
                <Editing
                    mode="row"
                    allowUpdating={true}
                    allowDeleting={true}
                >                
                    <Form>
                        <SimpleItem dataField="id" disabled={true} />
                        <SimpleItem dataField="name" disabled={true} />
                        <SimpleItem dataField="clubId" disabled={true} />
                        <SimpleItem dataField="ein">
                            <PatternRule
                                message={'EIN number should only contain digits'}
                                pattern={/^[0-9]+$/}
                            />
                            <PatternRule
                                message={'EIN number should be 9 digits'}
                                pattern={/^\d{9}$/i}
                            />
                            <RequiredRule message="EIN is required" />
                        </SimpleItem>
                    </Form>
                </Editing>
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} />
                <Column dataField="id" caption="Id" width={80} allowEditing={false} />
                <Column dataField="name" />
                <Column dataField="clubId" />
                <Column dataField="ein" />
                <Column dataField="ngf" />
                <Column dataField="hubSpotId" />
                <Column dataField="cityCB" allowEditing={false} />
                <Column dataField="stateCB" allowEditing={false} />
                <Column dataField="city990" allowEditing={false} />
                <Column dataField="state990" allowEditing={false} />
            </DataGrid>
        );
    }
    onRowPrepared(e) {
        if (e.rowType == 'data') {
            if (e.data.groupId % 2 == 0) {
                //e.rowElement.style.backgroundColor = '#D3D3D3';
            }
            else {
                e.rowElement.style.backgroundColor = '#D3D3D3';
            }
        }
    }
}

function sendRequest(value) {
    const ein = value;
    // TO DO if needed
    return fetch("/api/<test>", {
        method: "GET",
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(result => result.json())
        .catch(handleErrors);
}

function asyncValidation(params) {
    return sendRequest(params.value);
}

function handleErrors(response) {
    if (!response.ok)
        throw Error(response.statusText);
    return response;
}

const gridDataSource = {
    store: new CustomStore({
        key: 'id',
        load: (values) => {
            return fetch("/api/cbeinngfhubspot/getdupeclubnames", {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(result => result.json())
                .catch(handleErrors);
        },

        update: (key, values) => {
            var jsonString = JSON.stringify(values);
            return fetch('/api/cbeinngfhubspot/' + key, {
                method: "PUT",
                body: jsonString,
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(handleErrors);
        },

        remove: (key) => {
            console.log(key);
            return fetch('/api/cbeinngfhubspot/' + key, {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(handleErrors);
        }
    })
}

export default UtilityDupeNames;
