import React, { Component } from 'react';
import { Container } from 'reactstrap';

export class Home extends Component {
  static displayName = Home.name;

  render () {
      return (
        <Container>
            <div>
                <h1>CBStar</h1>
                <p>This tool developed by Club Benchmarking will help you compare private clubs. Inside the <strong>CBStar</strong> application, there are six displays:</p>
                <ul>
                    <li><strong>Home</strong>: Summary of the application that works as a dashboard.</li>
                    <li><strong>My User Profile</strong>: Provides a portal for the user to change their email, change their password and manage other profile related actions.</li>
                    <li><strong>Sample PowerBI</strong>: Demonstrates the ability to integrate with a Power BI dashboard.</li>
                    <li><strong>EIN Data Grid</strong>: Summarizes the list of clubs and provides an ability to manually edit blank EINs.</li>
                    <li><strong>Extended Tax Form Data</strong>: On top of the functionality of the Tax Form data display, column configuration and Fixed columns are implemented in this display.</li>
                    <li><strong>Balance Sheet Report</strong>: Provides metrics\ratios off the 990 data including Net PPE to Assets, Liabilities to Assets, Debt to Equity, and others.</li>
                    <li><strong>Index File Summary</strong>: Lists the large data set of the Index File Summary records, along with additional capabilities like Column configuration and manual refresh. The page is loaded dynamically.</li>
                </ul>
              </div>
       </Container>
    );

  }
}


/* 
 Add more styling on this with images and font-awesome icons as well bootstrap css.
 
 */