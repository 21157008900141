import React, { Component, Fragment } from 'react';
import { NavItem, NavLink, UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle} from 'reactstrap';
import { Link } from 'react-router-dom';
import authService from './AuthorizeService';
import { ApplicationPaths } from './ApiAuthorizationConstants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Menu from 'devextreme-react/menu';

export class LoginMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            userName: null
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        this.setState({
            isAuthenticated,
            userName: user && user.name
        });
    }

    render() {
        const { isAuthenticated, userName } = this.state;
        if (!isAuthenticated) {
            const registerPath = `${ApplicationPaths.Register}`;
            const loginPath = `${ApplicationPaths.Login}`;
            return this.anonymousView(registerPath, loginPath);
        } else {
            const profilePath = `${ApplicationPaths.Profile}`;
            const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
            return this.authenticatedView(userName, profilePath, logoutPath);
        }
    }

    authenticatedView(userName, profilePath, logoutPath) {
        return (<Fragment>
            <NavItem>
                <NavLink tag={Link} className="text-dark" to={profilePath}>My User Profile</NavLink>
            </NavItem>
            <NavItem>
                <NavLink tag={Link} className="text-dark" to="/counter">Sample PowerBI</NavLink>
            </NavItem>
            <UncontrolledButtonDropdown>
                <DropdownToggle caret className="dx-menu-item dx-menu-item-text">
                    990 Reports
                </DropdownToggle>
                <DropdownMenu className="text-dark dx-menu-item-text">
                    <DropdownItem tag={Link} to="/extended-tax-form-report" className="text-dark dx-menu-item-text">Tax Form Data</DropdownItem>
                    <DropdownItem tag={Link} to="/tax-form-schedulej" className="text-dark dx-menu-item-text">Schedule J</DropdownItem>
                    <DropdownItem tag={Link} to="/tax-form-schedulej-officers" className="text-dark dx-menu-item-text">Schedule J Officers</DropdownItem>
                    <DropdownItem tag={Link} to="/tax-form-partvii-section-a" className="text-dark dx-menu-item-text">Part VII - Section A</DropdownItem>
                    <DropdownItem tag={Link} to="/tax-form-partvii-aggregate" className="text-dark dx-menu-item-text">Part VII - Aggregate</DropdownItem>
                </DropdownMenu>
            </UncontrolledButtonDropdown>
            <NavItem>
                <NavLink tag={Link} className="text-dark" to="/net-worth-report"><FontAwesomeIcon icon="chart-line" /> Net Worth Report</NavLink>
            </NavItem>
            <NavItem>
                <NavLink tag={Link} className="text-dark" to="/balance-sheet-report"> <FontAwesomeIcon icon="balance-scale" /> Balance Sheet Report</NavLink>
            </NavItem>
            <UncontrolledButtonDropdown>
                <DropdownToggle caret className="dx-menu-item dx-menu-item-text">
                    Data Utilities
                </DropdownToggle>
                <DropdownMenu className="text-dark dx-menu-item-text">
                    <DropdownItem tag={Link} to="/ein-data-grid" className="text-dark dx-menu-item-text">EIN Data Grid</DropdownItem>
                    <DropdownItem tag={Link} to="/index-file-summary" className="text-dark dx-menu-item-text">Index File Summary</DropdownItem>
                    <DropdownItem tag={Link} to="/utility-dupe-names" className="text-dark dx-menu-item-text">Duplicate Name Cleanup</DropdownItem>
                    <DropdownItem tag={Link} to="/utility-dupe-eins" className="text-dark dx-menu-item-text">Duplicate EIN Cleanup</DropdownItem>     
                    <DropdownItem tag={Link} to="/utility-cb-ein-hubspot-ngf-grid" className="text-dark dx-menu-item-text">CB EIN Hubspot NGF Grid</DropdownItem>
                </DropdownMenu>
            </UncontrolledButtonDropdown>
            <NavItem>
                <NavLink tag={Link} className="text-dark" to={logoutPath}>Logout</NavLink>
            </NavItem>
        </Fragment>);

    }

    anonymousView(registerPath, loginPath) {
        return (<Fragment>
            <NavItem>
                <NavLink tag={Link} className="text-dark" to={loginPath}>Login</NavLink>
            </NavItem>
        </Fragment>);
    }
}
