import React from 'react';

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

import { Template } from 'devextreme-react/core/template';
//import TreeList, { Column, ColumnChooser, HeaderFilter, SearchPanel, Selection, Lookup, Pager, Scrolling, Paging} from 'devextreme-react/tree-list';

import DataGrid, {
    Column,
    FormItem,
    Pager,
    HeaderFilter,
    SearchPanel,
    Form,
    Lookup,
    Grouping,
    GroupPanel,
    Export,
    Selection
} from 'devextreme-react/data-grid';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { CheckBox } from 'devextreme-react';
import CustomStore from "devextreme/data/custom_store";
//import { exportDataGrid } from 'devextreme/excel_exporter';
import 'whatwg-fetch';



const columns = ['ein', 'taxPeriod', 'taxpayerName', 'totalEmployeeCount', 'initiationFeesAmount', 'payrollTaxes', 'totalRevenue', 'grossSalesOfInventory'];
const allowedPageSizes = [10, 20, 50, 100];

class TaxFormViewerEntry extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            autoExpandAll: true
        };

        this.onAutoExpandAllChanged = this.onAutoExpandAllChanged.bind(this);
    }

    currencyFormat = { style: 'decimal', useGrouping: true };

    render() {
        return (
            <div>
                <DataGrid
                    dataSource={gridDataSource}
                    keyExpr="id"
                    allowColumnReordering={true}
                    showBorders={true}
                    wordWrapEnabled={true}
                    columnAutoWidth={true}
                >
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={allowedPageSizes}
                        showNavigationButtons={true}
                        showInfo={true}
                        infoText="Page {0} of {1} ({2} Tax Records)"
                    />

                    <Export enabled={true} fileName="TaxForm" allowExportSelectedData={true} />
                    <Selection mode="multiple" />
                    <GroupPanel visible={true} />
                    <HeaderFilter visible={true} />
                    <SearchPanel visible={true} />
                    <Grouping autoExpandAll={this.state.autoExpandAll} />

                    <Column dataField="ein" dataType="string" allowSearch="false"/>
                    <Column dataField="clubId" dataType="string" allowSearch="false" />
                    <Column dataField="taxPeriod" dataType="string" allowSearch="false"/>
                    <Column dataField="taxpayerName" dataType="string" allowSearch="true"/>
                    <Column dataField="city" dataType="string" allowSearch="false"/>
                    <Column dataField="totalAssetsEoy" dataType="number" format={this.currencyFormat} allowSearch="false"/>
                    <Column dataField="totalNetAssetsEoy" dataType="number" format={this.currencyFormat} allowSearch="false"/>
                    <Column dataField="taxYear" dataType="string" groupIndex={0} allowSearch="false"/>
                    <Column dataField="state" dataType="string" groupIndex={0} allowSearch="false"/>
                </DataGrid>
                <div className="options">
                    <div className="caption">Options</div>
                    <div className="option">
                        <CheckBox text="Expand All Groups"
                            value={this.state.autoExpandAll}
                            onValueChanged={this.onAutoExpandAllChanged} />
                    </div>
                </div>
            </div>
        );
    }

    // works
    //<Export enabled={true} fileName="TaxForm" allowExportSelectedData={true} />
    //<Selection mode="multiple" />

    //onExporting(e) {
    //    const workbook = new ExcelJS.Workbook();
    //    const worksheet = workbook.addWorksheet('Main sheet');
    //    exportDataGrid({
    //        component: e.component,
    //        worksheet: worksheet,
    //        customizeCell: function (options) {
    //            const excelCell = options;
    //            excelCell.font = { name: 'Arial', size: 12 };
    //            excelCell.alignment = { horizontal: 'left' };
    //        }
    //    }).then(function () {
    //        workbook.xlsx.writeBuffer()
    //            .then(function (buffer) {
    //                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
    //            });
    //    });
    //    e.cancel = true;
    //}

    onAutoExpandAllChanged() {
        this.setState({
            autoExpandAll: !this.state.autoExpandAll
        });
    }

    //render() {
    //    return (
    //        <TreeList
    //            dataSource={treeDataSource}
    //            defaultColumns={columns}
    //            showBorders={true}
    //            wordWrapEnabled={true}
    //            keyExpr="id"
    //            //parentIdExpr="ein"
    //            id="treeDataSource"
    //            rootValue={-1}
    //            expandedRowKeys={[1, 2]}
    //        >
    //            <Scrolling
    //                mode="standard" />
    //            <Paging
    //                enabled={true}
    //                defaultPageSize={10} />
    //            <Pager
    //                showPageSizeSelector={true}
    //                allowedPageSizes={allowedPageSizes}
    //                showInfo={true} />
    //        </TreeList>
    //    );
    //}

}

function handleErrors(response) {
    if (!response.ok)
        throw Error(response.statusText);
    return response;
}
const gridDataSource = {
    store: new CustomStore({
        //key: 'id',
        load: (values) => {
            return fetch("/api/taxforms", {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(result => result.json())
                .catch(handleErrors);
        },

        update: (key, values) => {
            console.log('values', values);
            var jsonString = JSON.stringify(values);
            return fetch('/api/ein/' + key, {
                method: "PUT",
                body: jsonString,
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(handleErrors);
        }
    })
}

export default TaxFormViewerEntry;
