import React, { Component } from 'react';

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

import { Template } from 'devextreme-react/core/template';

import DataGrid, {
    Column,
    ColumnChooser,
    Export,
    Form,
    FormItem,
    Grouping,
    GroupPanel,
    HeaderFilter,
    LoadPanel,
    Lookup,
    Pager,
    Paging,
    RemoteOperations,
    Scrolling,
    SearchPanel,
    Selection,
    Toolbar
} from 'devextreme-react/data-grid';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { CheckBox } from 'devextreme-react';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import CustomStore from "devextreme/data/custom_store";
import query from 'devextreme/data/query';


const columns = ['id', 'returnId', 'filingType', 'ein', 'taxPeriod', 'subDate', 'taxpayerName', 'returnType', 'dln', 'objectId', 'xmlFileName']
const allowedPageSizes = [10, 20, 50, 100];

class IndexFileSummaryLargeData extends Component {

    constructor(props) {
        super(props);

        this.state = {
            autoExpandAll: true,
            totalCount: this.getCount('id')
        };

        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.toolbarItemRender = this.toolbarItemRender.bind(this);
        this.dataGrid = null;
    }
    getCount(field) {
        debugger;
        console.log("this", this);
        //return query(this);

	}
    toolbarItemRender() {
        //return (
        //    <div className="informer">
        //        <h2 className="count">{this.state.totalCount}</h2>
        //        <span className="name">Total Count</span>
        //    </div>
        //);
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
            location: 'before',
            template: 'totalGroupCount'
        },
            {
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.refreshDataGrid.bind(this)
            }
        });
    }

    currencyFormat = { style: 'decimal', useGrouping: true };

    refreshDataGrid() {
        this.dataGrid.instance.refresh();
    }

    render() {
        return (
            <div>
                <DataGrid
                    DataGrid id="gridContainer"
                    ref={(ref) => this.dataGrid = ref}
                    dataSource={remoteDataSource}
                    allowColumnReordering={true}
                    showBorders={true}
                    wordWrapEnabled={true}
                    columnAutoWidth={true}
                    remoteOperations={true}
                    defaultColumns={columns}
                    onToolbarPreparing={this.onToolbarPreparing}
                >
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={allowedPageSizes}
                        showNavigationButtons={true}
                        showInfo={true}
                        infoText="Page {0} of {1} ({2} Tax Records)"
                    />
                    <RemoteOperations
                        filtering={true}
                        //paging={true}
                        sorting={true}
                        summary={true}
                        grouping={true}
                        groupPaging={true}
                    />
                    <ColumnChooser enabled={true} />
                    <LoadPanel enabled={true} />
                    <Template name="totalGroupCount" render={this.toolbarItemRender} />
                    <Scrolling mode="virtual" rowRenderingMode="virtual" />
                    <Paging pageSize="100" />
                    <Export enabled={true} fileName="TaxForm" allowExportSelectedData={true} />
                    <Selection mode="multiple" />
                    <GroupPanel visible={true} />
                    <HeaderFilter visible={true} />
                    <SearchPanel visible={true} />
                    <Grouping autoExpandAll={this.state.autoExpandAll} />

                </DataGrid>
            </div>
        );
    }


    //onAutoExpandAllChanged() {
    //    this.setState({
    //        autoExpandAll: !this.state.autoExpandAll
    //    });
    //}

}

function handleErrors(response) {
    if (!response.ok)
        throw Error(response.statusText);
    return response;
}

const serviceUrl = '/api/IndexFiles';

const remoteDataSource = createStore({
    key: 'id',
    loadUrl: serviceUrl + '/indexfiles',
    insertUrl: serviceUrl + '/InsertAction',
    updateUrl: serviceUrl + '/UpdateAction',
    deleteUrl: serviceUrl + '/DeleteAction'
});

export default IndexFileSummaryLargeData;
