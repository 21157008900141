import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { FetchData } from './components/FetchData';
import { Counter } from './components/Counter';

import './custom.css'
import EINDataGrid from './components/EINDataGrid';
import TaxTreeGrid from './components/TaxTreeGrid';
import ExtendedTaxForm from './components/ExtendedTaxFormReport';
import TaxFormViewerEditor from './components/TaxFormViewerEditor';
import IndexFileSummaryLargeData from './components/IndexFileSummaryLargeData';
import BalanceSheetReport from './components/BalanceSheetReport';
import FileDownloadViewer from './components/FileDownloadInfoViewer';
import NetWorthReport from './components/NetWorthReport';
import TaxFormScheduleJ from './components/TaxFormScheduleJ';
import TaxFormScheduleJOfficer from './components/TaxFormScheduleJOfficer';
import TaxFormPartVii from './components/TaxFormPartVii';
import TaxFormPartViiAggregate from './components/TaxFormPartViiAggregate';
import UtilityDupeNames from './components/UtilityDupeNames';
import UtilityDupeEins from './components/UtilityDupeEins';
import UtilityCBEINHubspotNGFGrid from './components/UtilityCBEINHubspotNGFGrid';

import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import ReactDOM from 'react-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faCheckSquare, faBalanceScale, faChalkboard, faChartLine } from '@fortawesome/free-solid-svg-icons';

library.add(fab, faCheckSquare, faBalanceScale, faChalkboard, faChartLine);

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Layout>
                <Route exact path='/' component={Home} />
                <Route path='/system-dashboard' component={FileDownloadViewer} />
                <Route path='/counter' component={Counter} />
                <Route path='/fetch-data' component={FetchData} />
                <Route path='/ein-data-grid' component={EINDataGrid} />
                <Route path='/extended-tax-form-report' component={ExtendedTaxForm} />
                <Route path='/tax-form-schedulej' component={TaxFormScheduleJ} />
                <Route path='/tax-form-schedulej-officers' component={TaxFormScheduleJOfficer} />
                <Route path='/tax-form-partvii-section-a' component={TaxFormPartVii} />
                <Route path='/tax-form-partvii-aggregate' component={TaxFormPartViiAggregate} />
                <Route path='/net-worth-report' component={NetWorthReport} />
                <Route path='/balance-sheet-report' component={BalanceSheetReport} />
                <Route path='/tax-form-viewer-entry' component={TaxFormViewerEditor} />
                <Route path='/index-file-summary' component={IndexFileSummaryLargeData} />
                <Route path='/utility-dupe-names' component={UtilityDupeNames} />
                <Route path='/utility-dupe-eins' component={UtilityDupeEins} />
                <Route path='/utility-cb-ein-hubspot-ngf-grid' component={UtilityCBEINHubspotNGFGrid} />
                <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
            </Layout>
        );
    }
}
