import React from 'react';

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing,
    Pager,
    HeaderFilter,
    Grouping,
    GroupPanel,
    Export,
    SearchPanel,
    Selection,
    StateStoring
} from 'devextreme-react/data-grid';

import Box, {
    Item
} from 'devextreme-react/box';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CheckBox } from 'devextreme-react';
import CustomStore from "devextreme/data/custom_store";
import 'whatwg-fetch';


const allowedPageSizes = [10, 15, 20, 25, 30, 40, 50, 100];

class BalanceSheetReport extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            autoExpandAll: true
        };

        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.onRowPrepared = this.onRowPrepared.bind(this);
        this.refreshDataGrid = this.refreshDataGrid.bind(this);

        this.dataGrid = null;

        this.onAutoExpandAllChanged = this.onAutoExpandAllChanged.bind(this);
    }

    currencyFormat = { type: 'thousands', useGrouping: true };
    percentageFormat = { type: 'percent', precision: 1 };

    render() {
        return (
            <React.Fragment>
                <Box
                    direction="col"
                    width="100%"
                    height={250}>
                    <Item ratio={1}>
                        <div className="rect demo-dark header">
                            <FontAwesomeIcon icon="balance-scale" /> Balance Sheet Report
                        </div>
                    </Item>
                    <Item
                        ratio={4}
                        baseSize={0}>
                        <Box
                            direction="row"
                            width="100%"
                            height={125}>
                            <Item ratio={14}>
 
                                    <div>
                                        <DataGrid
                                            dataSource={gridDataSource}
                                            keyExpr="id"
                                            allowColumnReordering={true}
                                            showBorders={true}
                                            wordWrapEnabled={false}
                                            columnAutoWidth={true}
                                            showRowLines={true}
                                            showColumnHeaders={true}
                                            onRowPrepared={this.onRowPrepared}
                                        >
                                            <ColumnChooser enabled={true} />
                                            <ColumnFixing enabled={true} />
                                            <Pager
                                                showPageSizeSelector={true}
                                                allowedPageSizes={allowedPageSizes}
                                                showNavigationButtons={true}
                                                showInfo={true}
                                                infoText="Page {0} of {1} ({2} Tax Records)"
                                            />
                                            <Export enabled={true} fileName="BalanceSheetReport" allowExportSelectedData={true} customizeExcelCell={this.customizeExcelCell} />
                                            <Selection mode="multiple" />
                                            <GroupPanel visible={true} />
                                            <HeaderFilter
                                                visible={true}
                                                allowSearch={true}
                                            />
                                            <SearchPanel visible={true} searchVisibleColumnsOnly={true} />
                                            <Grouping autoExpandAll={this.state.autoExpandAll} />

                                            <Column dataField="id" dataType="string" visible={false} />
                                            <Column dataField="fiscalYe" dataType="string" caption="Fiscal YE" fixed={true} />
                                            <Column dataField="clubName" dataType="string" fixed={false} searchEnabled={true} allowHeaderFiltering={true} fixed={true} />
                                            <Column dataField="address" dataType="number" visible={false} />
                                            <Column dataField="city" dataType="number" format={this.currencyFormat} visible={false} />
                                            <Column dataField="state" dataType="number" format={this.currencyFormat} />
                                            <Column dataField="zip" dataType="number" format={this.currencyFormat} visible={false} />
                                            <Column dataField="assets" number="number" format={this.currencyFormat} />
                                            <Column dataField="depreciation" number="number" format={this.currencyFormat} />
                                            <Column dataField="una" dataType="number" caption="UNA" format={this.currencyFormat} />
                                            <Column dataField="liabilities" dataType="number" format={this.currencyFormat} />
                                            <Column dataField="debt" dataType="number" format={this.currencyFormat} />
                                            <Column dataField="receipts" dataType="number" format={this.currencyFormat} />
                                            <Column dataField="grossPpe" dataType="number" caption="Gross PPE" format={this.currencyFormat} />
                                            <Column dataField="netPpe" dataType="number" caption="Net PPE" format={this.currencyFormat} />
                                            <Column dataField="land" dataType="number" format={this.currencyFormat} />
                                            <Column dataField="cash" dataType="number" format={this.currencyFormat} />
                                            <Column dataField="netPpeCost" dataType="number" caption="Net PPE to Cost (Gross PPE)" format={this.percentageFormat} />
                                            <Column dataField="netPpeGrossLessLand" dataType="number" caption="Net PPE/Gross Less Land" format={this.percentageFormat} />
                                            <Column dataField="netPpeAssets" dataType="number" caption="Net PPE to Assets" format={this.percentageFormat} />
                                            <Column dataField="liabilitiesAssets" dataType="number" caption="Liabilities to Assets" format={this.percentageFormat} />
                                            <Column dataField="equityAssets" dataType="number" caption="Equity to Assets" format={this.percentageFormat} />
                                            <Column dataField="debtEquity" dataType="number" caption="Debt to Equity" format={this.percentageFormat} />
                                            <Column dataField="cashAssets" dataType="number" caption="Cash to Assets" format={this.percentageFormat} />
                                            <Column dataField="depreciationRevenue" dataType="number" caption="Depreciation to Revenue" format={this.percentageFormat} />
                                        </DataGrid>
                                        <div className="options">
                                            <div className="caption">Options</div>
                                            <div className="option">
                                                <CheckBox text="Expand All Groups"
                                                    value={this.state.autoExpandAll}
                                                    onValueChanged={this.onAutoExpandAllChanged} />
                                            </div>
                                        </div>
                                    </div>
                            </Item>
                        </Box>
                    </Item>
                </Box>
            
            </React.Fragment>
        );
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'refresh',
                    onClick: this.refreshDataGrid
                }
            });
    }

    onRowPrepared(e) {
        if (e.rowType == 'data' && (!e.data.assets || !e.data.una || !e.data.liabilities || !e.data.debt
            || !e.data.receipts || !e.data.grossPpe || !e.data.netPpe || !e.data.land || !e.data.cash))
        {
            e.rowElement.style.backgroundColor = '#FFCCCB';
        }
    }

    refreshDataGrid() {
        window.location.reload();
    }

    customizeExcelCell(options) {
        var e = options.gridCell;
        if (!e) {
            return;
        }
        if (e.rowType == 'data' && (!e.data.assets || !e.data.una || !e.data.liabilities || !e.data.debt
            || !e.data.receipts || !e.data.grossPpe || !e.data.netPpe || !e.data.land || !e.data.cash))
        {
            options.format = '#FFCCCB';
        }
    }

    onAutoExpandAllChanged() {
        this.setState({
            autoExpandAll: !this.state.autoExpandAll
        });
    }

}

function handleErrors(response) {
    if (!response.ok)
        throw Error(response.statusText);
    return response;
}
const gridDataSource = {
    store: new CustomStore({
        load: (values) => {
            return fetch("/api/balancesheet", {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(result => result.json())
                .catch(handleErrors);
        },

        update: (key, values) => {
            console.log('values', values);
            var jsonString = JSON.stringify(values);
            return fetch('/api/balancesheet/' + key, {
                method: "PUT",
                body: jsonString,
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(handleErrors);
        }
    })
}

export default BalanceSheetReport;
