import React from 'react';

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

import { Template } from 'devextreme-react/core/template';

import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing,
    FormItem,
    Pager,
    HeaderFilter,
    Form,
    Lookup,
    Grouping,
    GroupPanel,
    Export,
    Scrolling,
    SearchPanel,
    Selection
} from 'devextreme-react/data-grid';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { CheckBox } from 'devextreme-react';
import CustomStore from "devextreme/data/custom_store";
import 'whatwg-fetch';



const columns = ['ein', 'taxPeriod', 'taxpayerName', 'totalEmployeeCount', 'initiationFeesAmount', 'payrollTaxes', 'totalRevenue', 'grossSalesOfInventory'];
const allowedPageSizes = [10, 20, 50, 100];

class FileDownloadViewer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            autoExpandAll: true
        };

        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        //this.toolbarItemRender = this.toolbarItemRender.bind(this);
        this.dataGrid = null;

        this.onAutoExpandAllChanged = this.onAutoExpandAllChanged.bind(this);
    }

    currencyFormat = { style: 'decimal', useGrouping: true };

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
            location: 'before',
            template: 'totalGroupCount'
        }, {
            location: 'before',
            widget: 'dxSelectBox',
            options: {
                width: 200,
                items: [{
                    value: 'Id',
                    text: 'Grouping by Id'
                }, {
                    value: 'taxPayerName',
                    text: 'Grouping by Tax Payer Name'
                }],
                displayExpr: 'text',
                valueExpr: 'value',
                value: 'remoteDataSource',
                //onValueChanged: this.groupChanged.bind(this)
            }
        }
            ,
            //{
            //location: 'before',
            //widget: 'dxButton',
            //options: {
            //    width: 136,
            //    text: 'Test Button',
            //    //onClick: this.collapseAllClick.bind(this)
            //}
            //},

            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'refresh',
                    onClick: this.refreshDataGrid.bind(this)
                }
            });
    }

    refreshDataGrid() {
        this.dataGrid.instance.refresh();
    }

    render() {
        return (
            <div>
                <DataGrid
                    //DataGrid id="gridContainer"
                    //ref={(ref) => this.dataGrid = ref}
                    dataSource={gridDataSource}
                    keyExpr="id"
                    allowColumnReordering={true}
                    showBorders={true}
                    wordWrapEnabled={true}
                    columnAutoWidth={true}
                    showRowLines={true}
                    showColumnHeaders={true}
                >
                    <ColumnChooser enabled={true} />
                    <ColumnFixing enabled={true} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={allowedPageSizes}
                        showNavigationButtons={true}
                        showInfo={true}
                        infoText="Page {0} of {1} ({2} Tax Records)"
                    />
                    <Export enabled={true} fileName="TaxForm" allowExportSelectedData={true} />
                    <Selection mode="multiple" />
                    <GroupPanel visible={true} />
                    <HeaderFilter visible={true} />
                    <SearchPanel visible={true} />
                    <Grouping autoExpandAll={this.state.autoExpandAll} />

                    <Column dataField="ein" dataType="string" groupIndex={0} />
                    <Column dataField="taxPeriod" dataType="string" />
                    <Column dataField="taxpayerName" dataType="string" groupIndex={0} fixed={true} />
                    <Column dataField="totalEmployeeCount" dataType="number" />
                    <Column dataField="initiationFeesAmount" dataType="number" format={this.currencyFormat} />
                    <Column dataField="payrollTaxes" dataType="number" format={this.currencyFormat} />
                    <Column dataField="totalRevenue" dataType="number" format={this.currencyFormat} />
                    <Column dataField="grossSalesOfInventory" number="number" format={this.currencyFormat} />
                    <Column dataField="totalAssetsEoy" dataType="number" format={this.currencyFormat} />
                    <Column dataField="taxYear" dataType="string" />
                    <Column dataField="cashNonInterestBearingEoy" dataType="number" format={this.currencyFormat} />
                    <Column dataField="totalLiabilitiesAndNetAssetsEoy" dataType="number" format={this.currencyFormat} />
                    <Column dataField="landBuildingsEquipmentNetEoy" dataType="number" format={this.currencyFormat} />
                    <Column dataField="investmentInPubliclyTradedSecuritiesEoy" dataType="number" format={this.currencyFormat} />
                    <Column dataField="investmentsOtherSecuritiesEoy" dataType="number" format={this.currencyFormat} />
                    <Column dataField="land" dataType="number" format={this.currencyFormat} />
                    <Column dataField="addressLine1" dataType="string" />
                    <Column dataField="city" dataType="string" />
                    <Column dataField="state" dataType="string" />
                    <Column dataField="zipCode" dataType="string" />
                    <Column dataField="grossReceipts" dataType="number" format={this.currencyFormat} />
                    <Column dataField="programServiceRevenueCY" dataType="number" format={this.currencyFormat} />
                    <Column dataField="investmentIncomeCY" dataType="number" format={this.currencyFormat} />
                    <Column dataField="otherRevenueCY" dataType="number" format={this.currencyFormat} />
                </DataGrid>
                <div className="options">
                    <div className="caption">Options</div>
                    <div className="option">
                        <CheckBox text="Expand All Groups"
                            value={this.state.autoExpandAll}
                            onValueChanged={this.onAutoExpandAllChanged} />
                    </div>
                </div>
            </div>
        );
    }

    // works
    //<Export enabled={true} fileName="TaxForm" allowExportSelectedData={true} />
    //<Selection mode="multiple" />

    //onExporting(e) {
    //    const workbook = new ExcelJS.Workbook();
    //    const worksheet = workbook.addWorksheet('Main sheet');
    //    exportDataGrid({
    //        component: e.component,
    //        worksheet: worksheet,
    //        customizeCell: function (options) {
    //            const excelCell = options;
    //            excelCell.font = { name: 'Arial', size: 12 };
    //            excelCell.alignment = { horizontal: 'left' };
    //        }
    //    }).then(function () {
    //        workbook.xlsx.writeBuffer()
    //            .then(function (buffer) {
    //                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
    //            });
    //    });
    //    e.cancel = true;
    //}

    onAutoExpandAllChanged() {
        this.setState({
            autoExpandAll: !this.state.autoExpandAll
        });
    }

    //render() {
    //    return (
    //        <TreeList
    //            dataSource={treeDataSource}
    //            defaultColumns={columns}
    //            showBorders={true}
    //            wordWrapEnabled={true}
    //            keyExpr="id"
    //            //parentIdExpr="ein"
    //            id="treeDataSource"
    //            rootValue={-1}
    //            expandedRowKeys={[1, 2]}
    //        >
    //            <Scrolling
    //                mode="standard" />
    //            <Paging
    //                enabled={true}
    //                defaultPageSize={10} />
    //            <Pager
    //                showPageSizeSelector={true}
    //                allowedPageSizes={allowedPageSizes}
    //                showInfo={true} />
    //        </TreeList>
    //    );
    //}

}

function handleErrors(response) {
    if (!response.ok)
        throw Error(response.statusText);
    return response;
}
const gridDataSource = {
    store: new CustomStore({
        //key: 'id',
        load: (values) => {
            return fetch("/api/taxforms", {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(result => result.json())
                .catch(handleErrors);
        },

        update: (key, values) => {
            console.log('values', values);
            var jsonString = JSON.stringify(values);
            return fetch('/api/ein/' + key, {
                method: "PUT",
                body: jsonString,
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(handleErrors);
        }
    })
}

export default FileDownloadViewer;
